<template>
  <!-- 发货信息 -->
  <div class="wrapper">
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="7" border>
      <el-descriptions-item label="快递单号" label-class-name="my-label">{{ orderDelivery.deliveryNo }}</el-descriptions-item>
      <el-descriptions-item label="手机串号" label-class-name="my-label">{{ orderDelivery.serialNo }}</el-descriptions-item>
      <el-descriptions-item label="发货时间" label-class-name="my-label">{{ detailInfo.deliveryTime }} </el-descriptions-item>
      <el-descriptions-item label="确认收货时间" label-class-name="my-label">{{ detailInfo.receiveTime }} </el-descriptions-item>
      <el-descriptions-item label="采购价" label-class-name="my-label">{{ detailInfo.costPrice }} </el-descriptions-item>
      <el-descriptions-item label="发货图片" label-class-name="my-label">
        <el-image style="width: 50px; height: 50px;" :src="orderDelivery.deliveryImageUrl" :preview-src-list="[orderDelivery.deliveryImageUrl]">
        </el-image>
      </el-descriptions-item>
      <el-descriptions-item label="备注" label-class-name="my-label">{{ orderDelivery.remark }} </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: 'Address',
  props: {
    orderDelivery: {
      type: Object,
      default: () => {}
    },
    detailInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    // 编辑联系人
    editUser() {
      this.dialogVisibleUser = true
    },
    // 保存工作信息
    saveJob() {
      this.$confirm('工作单位未填写，您确定要提交吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .contact-remark {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .item {
      background: #fafafa;
      flex: 1;
      border: 1px solid #ebeef5;
      padding: 5px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .contact-remark-table {
    .header {
      margin-bottom: 10px;
      background: #fafafa;
      padding: 5px 10px;
      .handle-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          flex: 1;
          margin-right: 10px;
          .el-input {
            margin-right: 10px;
            width: 50%;
          }
        }
      }
    }
    .tp-table {
      .el-table__fixed-right {
        height: 100% !important;
      }
    }
  }
}
</style>
