<template>
  <!-- 基础信息 -->
  <div class="base-info-wrapper">
    <!-- 商品信息 -->
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="9" border>
      <el-descriptions-item label="订单号" :span="3" label-class-name="my-label">{{ $route.params.id }}</el-descriptions-item>
      <el-descriptions-item label="下单时间" :span="2" label-class-name="my-label">{{ detailInfo.createTime }}</el-descriptions-item>
      <el-descriptions-item label="订单状态" :span="2" label-class-name="my-label">{{ detailInfo.statusName }}</el-descriptions-item>
      <el-descriptions-item label="订单类型" :span="2" label-class-name="my-label">
        <el-tag type="primary" v-if="detailInfo.orderType == 1" size="mini">普通订单 </el-tag>
        <el-tag type="warning" v-if="detailInfo.orderType == 2" size="mini">门店订单 </el-tag>
        <el-tag type="success" v-if="detailInfo.orderType == 3" size="mini">卡券订单 </el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="商品描述" label-class-name="my-label">{{ orderGoods.skuTitle }}</el-descriptions-item>
      <el-descriptions-item label="总租金" label-class-name="my-label">{{ detailInfo.rentPrice }}</el-descriptions-item>
      <el-descriptions-item label="首付租金" label-class-name="my-label">{{ detailInfo.prepayRentPrice }}</el-descriptions-item>
      <el-descriptions-item label="租期" label-class-name="my-label">{{ detailInfo.billPeriodNum }}期</el-descriptions-item>
      <el-descriptions-item label="买断尾款" label-class-name="my-label">{{ detailInfo.buyoutPrice }}</el-descriptions-item>
      <el-descriptions-item label="意外保" label-class-name="my-label">{{ detailInfo.insurancePrice }}</el-descriptions-item>
      <el-descriptions-item label="溢价金" :span="2" label-class-name="my-label" content-class-name="my-content">
        <div class="handle">
          <span>{{ detailInfo.overflowPrice }}</span>
          <span>
            <!-- <el-link :underline="false" type="primary" @click="$router.push({ name: 'OrderUpdateGoods' })">修改</el-link> -->
          </span>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: 'BaseInfo',
  props: {
    detailInfo: {
      type: Object,
      default: () => {}
    }
  },
  provide() {
    return {
      context: this
    }
  },
  data() {
    return {}
  },
  computed: {
    orderGoods() {
      let goods = {}
      if (this.detailInfo) {
        goods = this.detailInfo.orderGoods
      }
      return goods
    }
  },
  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.base-info-wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
