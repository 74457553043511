<template>
  <!-- 收货信息 -->
  <div class="wrapper">
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="3" border>
      <el-descriptions-item label="联系人" label-class-name="my-label">{{ orderAddress.contactName }}</el-descriptions-item>
      <el-descriptions-item label="联系电话" label-class-name="my-label">{{ orderAddress.contactMobile }}</el-descriptions-item>
      <el-descriptions-item label="详细地址" label-class-name="my-label" content-class-name="my-content">
        <div class="handle">
          <span>{{ orderAddress.province }}{{ orderAddress.city }}{{ orderAddress.district }}{{ orderAddress.detail }}</span>
          <span>
            <!-- <el-link :underline="false" type="primary" @click="editUser">编辑</el-link> -->
          </span>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: 'Address',
  props: {
    orderAddress: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    // 编辑联系人
    editUser() {
      this.dialogVisibleUser = true
    },
    // 保存工作信息
    saveJob() {
      this.$confirm('工作单位未填写，您确定要提交吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .contact-remark {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .item {
      background: #fafafa;
      flex: 1;
      border: 1px solid #ebeef5;
      padding: 5px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .contact-remark-table {
    .header {
      margin-bottom: 10px;
      background: #fafafa;
      padding: 5px 10px;
      .handle-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          flex: 1;
          margin-right: 10px;
          .el-input {
            margin-right: 10px;
            width: 50%;
          }
        }
      }
    }
    .tp-table {
      .el-table__fixed-right {
        height: 100% !important;
      }
    }
  }
}
</style>
