<template>
  <!-- 紧急联系人 -->
  <div class="wrapper">
    <!-- 紧急联系人、备注 -->
    <div class="contact-remark">
      <div class="item">
        <span>紧急联系人</span>
        <el-button type="primary" size="small" @click="showContact">查看</el-button>
      </div>
      <!-- <div class="item">
        <span>备注</span>
        <el-button type="primary" size="small" @click="showRemark">查看</el-button>
      </div> -->
    </div>
    <!-- 联系人列表 -->
    <el-dialog title="紧急联系人" :visible.sync="outerVisibleContact">
      <!-- 添加联系人 -->
      <el-dialog width="40%" title="添加、编辑联系人" :visible.sync="innerVisibleContact" append-to-body>
        <el-form ref="formUser" :model="userFormData" label-width="80px">
          <el-form-item label="联系人" prop="relationName" :rules="rules">
            <el-input style="width:300px;" v-model="userFormData.relationName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="relationMobile" :rules="rules">
            <el-input style="width:300px;" v-model="userFormData.relationMobile" size="small"></el-input>
          </el-form-item>
          <el-form-item label="关系类型" prop="relationType" :rules="rules">
            <SelectDict width="300px" v-model="userFormData.relationType" dictCode="member:relationType"></SelectDict>
          </el-form-item>
          <el-form-item label="备注">
            <el-input style="width:300px;" type="textarea" v-model="userFormData.remark"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button @click="innerVisibleContact = false" size="small">取 消</el-button>
            <el-button type="primary" @click="saveRelation" size="small">提 交</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <div class="contact-remark-table">
        <div class="contact-add">
          <el-button type="info" size="mini" @click="copyRelation">复制</el-button>
          <el-button type="primary" size="mini" @click="addRelation">添加</el-button>
        </div>
        <tp-table :showPagination="false" :tableData="userList" :columns="userColumns" />
      </div>
    </el-dialog>
    <!-- 备注列表 -->
    <el-dialog title="备注列表" :visible.sync="dialogVisibleRemark" width="width">
      <div class="contact-remark-table">
        <div class="remark-content">
          <div class="remark-input">
            <el-input v-model="remarkData.content" size="small" clearable placeholder="请输入"></el-input>
          </div>
          <div class="remark-submit">
            <el-button type="primary" size="mini" @click="handleAddRemark">发送</el-button>
          </div>
        </div>
        <tp-table
          :showPagination="true"
          :tableData="remarkList"
          :columns="remarkColumns"
          :totalNum="remarkTotal"
          :current-page.sync="currentPageRemark"
          :pageSize.sync="pageSizeRemark"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRemarkListAPI,
  handleAddRemarkAPI,
  getRelationListAPI,
  addRelationAPI,
  editRelationAPI,
  getRelationDetailAPI,
  delRelationAPI,
  riskRelationAPI
} from './api'
import SelectDict from '@/views/components/select-dict.vue'
const userColumns = [
  {
    label: 'id',
    prop: 'id',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '姓名',
    prop: 'relationName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['relationName']}</p>
    }
  },
  {
    label: '电话',
    prop: 'relationMobile',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['relationMobile']}</p>
    }
  },

  {
    label: '关系',
    prop: 'relationTypeName',
    minWidth: '180',
    customRender(h, row) {
      return <div>{row['relationTypeName']}</div>
    }
  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['remark']}</p>
    }
  },
  {
    label: '操作',
    minWidth: '90',
    customRender(h, row) {
      return (
        <div>
          <el-link style="margin-right:10px;" underline={false} type="primary" onClick={() => this.editRelation(row['id'])}>
            编辑
          </el-link>
          <el-link underline={false} type="primary" onClick={() => this.delRelation(row['id'])}>
            删除
          </el-link>
        </div>
      )
    }
  }
]
const remarkColumns = [
  {
    label: '时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '审核人',
    prop: 'identitraceUsertyNo',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['traceUser']?.['userName']}</p>
    }
  },
  {
    label: '备注',
    prop: 'content',
    width: '200',
    fixed: 'right',
    customRender(h, row) {
      return <p>{row['content']}</p>
    }
  }
]
export default {
  name: 'Contact',
  props: ['member'],
  components: { SelectDict },
  data() {
    return {
      rules: [{ required: true, message: '该项崩为空', trigger: 'blur' }],
      // 编辑联系人相关
      outerVisibleContact: false,
      innerVisibleContact: false,
      userFormData: {
        relationName: '',
        relationMobile: '',
        relationType: '',
        remark: ''
      },
      userList: [],
      userColumns,
      // 备注列表
      dialogVisibleRemark: false,
      remarkColumns,
      remarkList: [],
      remarkData: {
        content: '',
        orderNo: this.$route.params.id
      },
      currentPageRemark: 1,
      pageSizeRemark: 10,
      remarkTotal: 0
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPageRemark() {
      this.getRemarkList()
    },
    pageSizeRemark() {
      this.getRemarkList()
    }
  },
  mounted() {},

  methods: {
    async getRemarkList() {
      let params = { orderNo: this.$route.params.id, page: this.currentPageRemark, pageSize: this.pageSizeRemark }
      const res = await getRemarkListAPI(params)
      this.remarkList = res.list || []
      this.remarkTotal = res.total || 0
    },
    // 查看备注
    showRemark() {
      this.dialogVisibleRemark = true
      this.remarkData = {
        content: '',
        orderNo: this.$route.params.id
      }
      this.getRemarkList()
    },
    //  添加备注
    handleAddRemark() {
      handleAddRemarkAPI(this.remarkData).then(() => {
        this.$message.success('发送成功')
        this.getRemarkList()
        this.remarkData.content = ''
      })
    },
    // 查看联系人
    showContact() {
      this.outerVisibleContact = true
      this.getRelationList()
    },
    // 联系人列表
    async getRelationList() {
      this.userList = await getRelationListAPI(this.member.id)
    },
    // 打开添加弹窗
    addRelation() {
      this.innerVisibleContact = true
      this.userFormData = {
        relationName: '',
        relationMobile: '',
        relationType: '',
        remark: '',
        memberId: this.member.id
      }
    },
    // 添加联系人
    async saveRelation() {
      const { id, relationMobile } = this.userFormData
      const { riskAlertList } = await riskRelationAPI({ id, relationMobile })
      if (riskAlertList.length == 0) return this.submit()

      this.$confirm('<div>' + riskAlertList.join('<br>') + '</div>', '提示', {
        confirmButtonText: '继续提交',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      })
          .then(() => {
            this.submit()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消保存'
            })
          })
    },
    // 提交
    submit() {
      if (this.userFormData.id) {
        editRelationAPI(this.userFormData).then(() => {
          this.$message.success('编辑成功')
          this.innerVisibleContact = false
          this.getRelationList()
        })
      } else {
        addRelationAPI(this.userFormData).then(() => {
          this.$message.success('添加成功')
          this.innerVisibleContact = false
          this.getRelationList()
        })
      }
    },
    // 编辑联系人
    editRelation(id) {
      this.innerVisibleContact = true
      this.getRelationDetail(id)
    },
    async getRelationDetail(id) {
      this.userFormData = await getRelationDetailAPI(id)
    },
    delRelation(id) {
      this.$confirm('此操作将永久删除该联系人, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delRelationAPI(id).then(() => {
            this.getRelationList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 复制
    copyRelation() {
      const { userList } = this
      let str = ''
      userList.forEach(item => {
        str = str + item.relationName + '，' + item.relationMobile + '，' + item.relationTypeName + '，' + item.remark + '\n'
      })

      this.$copyText(str).then(
        () => {
          this.$message.success('复制成功')
        },
        () => {
          this.$message.error('复制失败')
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-dialog__body /deep/ {
    padding-top: 10px;
    .relationType {
      .el-select {
        width: 300px !important;
      }
    }
  }
  .contact-remark {
    margin-bottom: 20px;
    // display: flex;
    // align-items: center;
    .item {
      background: #fafafa;
      flex: 1;
      border: 1px solid #ebeef5;
      padding: 5px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      &:first-child {
        margin-right: 20px;
        
      }
    }
  }
  .contact-remark-table {
    .tp-table {
      .el-table__fixed-right {
        height: 100% !important;
      }
    }
    .remark-content {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .remark-input {
        flex: 1;
        margin-right: 20px;
      }
      // .remark-submit {
      // }
    }
    .contact-add {
      text-align: right;
      margin-bottom: 20px;
    }
  }
}
</style>
