<template>
  <div class="wrapper">
    <div class="id-card">
      <div class="img">
        <el-image
          style="width: 100px; height: 100px;margin-right:20px;"
          :src="member.identityFrontImageUrl"
          :preview-src-list="[member.identityFrontImageUrl]"
        >
        </el-image>
        <el-image
          style="width: 100px; height: 100px;margin-right:20px;"
          :src="member.identityBackImageUrl"
          :preview-src-list="[member.identityBackImageUrl]"
        >
        </el-image>
      </div>
      <div class="info">
        <el-descriptions direction="vertical" :column="5" border>
          <el-descriptions-item label="姓名" label-class-name="my-label">{{ member.memberName }}</el-descriptions-item>
          <el-descriptions-item label="年龄" label-class-name="my-label">{{ member.age }}</el-descriptions-item>
          <el-descriptions-item label="手机号" label-class-name="my-label">{{ member.mobile }}</el-descriptions-item>
          <el-descriptions-item label="身份证号" :span="2" label-class-name="my-label">{{ member.identityNo }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IdCard',
  props: {
    member: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .id-card {
    display: flex;
    align-items: center;
    // .img {
    // }
    .info {
      flex: 1;
    }
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
