<template>
  <div class="wrapper">
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="3" border>
      <el-descriptions-item label="工作单位" label-class-name="my-label">{{memberProfile.workCompany}}</el-descriptions-item>
      <el-descriptions-item label="岗位" label-class-name="my-label">{{memberProfile.jobName}}</el-descriptions-item>
      <el-descriptions-item label="薪资" label-class-name="my-label">{{memberProfile.income}}</el-descriptions-item>
      <el-descriptions-item label="芝麻分" label-class-name="my-label">{{memberProfile.zmScore}}</el-descriptions-item>
      <el-descriptions-item label="在租详情" :span="2" label-class-name="my-label" content-class-name="my-content">
        <div class="handle">
          <span>{{memberProfile.rentDetail}}</span>
          <span>
            <!-- <el-link :underline="false" type="primary" @click="saveJob">保存</el-link> -->
          </span>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: 'Job',
  props: {
    memberProfile: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    // 保存工作信息
    saveJob() {
      this.$confirm('工作单位未填写，您确定要提交吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
