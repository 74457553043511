<template>
  <div class="wrapper">
    <admin-title title="详情"></admin-title>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础信息" name="base"></el-tab-pane>
      <el-tab-pane label="三方数据" name="risk"></el-tab-pane>
    </el-tabs>
    <DetailBase v-show="activeName == 'base'"></DetailBase>
    <DetailRisk v-show="activeName == 'risk'"></DetailRisk>
  </div>
</template>

<script>
import DetailBase from './detail-base.vue'
import DetailRisk from "./detail-risk.vue";
export default {
  name: 'Detail',
  components: { DetailBase,DetailRisk },
  data() {
    return {
      activeName: 'base'
    }
  },

  mounted() {},

  methods: {
    async getReviewOrderDetail() {
      this.detailInfo = await getReviewOrderDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .title {
    font-weight: 600;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
