<template>
  <div class="wrapper">
    <!-- 商品信息 -->
    <BaseInfo :detailInfo="detailInfo || {}" />
    <div class="title">身份证照片</div>
    <IdCard :member="detailInfo.member || {}" />
    <div class="title">收货信息</div>
    <Address :orderAddress="detailInfo.orderAddress || {}" />
    <Job :memberProfile="detailInfo.memberProfile || {}" />
    <div class="title">发货信息</div>
    <SendInfo :detailInfo="detailInfo || {}" :orderDelivery="detailInfo.orderDelivery||{}" />
    <Contact :member="detailInfo.member||{}" />
    <!-- <div class="title">客户归属</div>
    <SelectCustomer />
    <div class="title">用户评分</div>
    <Rate></Rate> -->
    <el-divider></el-divider>
    <div style="text-align:right">
      <el-button type="" size="small" @click="$router.back()">取消</el-button>
    </div>
    <div style="height:60px;"></div>
  </div>
</template>

<script>
import { getOrderDetailAPI } from './api'
import BaseInfo from './modules/baseInfo.vue'
import Address from './modules/address.vue'
import SendInfo from './modules/send-info.vue'
import Contact from './modules/contact.vue'
import Job from './modules/job.vue'
import IdCard from './modules/id-card.vue'
export default {
  name: 'DetailBase',
  components: { BaseInfo, Address, SendInfo, Contact, Job, IdCard },
  data() {
    return {
      detailInfo: {}
    }
  },

  mounted() {
    this.getReviewOrderDetail()
  },

  methods: {
    async getReviewOrderDetail() {
      this.detailInfo = await getOrderDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .title {
    font-weight: 600;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
