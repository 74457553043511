<template>
  <div class="wrapper">
    <div class="title">百融</div>
    <!-- <div class="sub-title">债偿压力指数：{{baiRong.debtRepayStress}}</div> -->
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="4" border>
      <el-descriptions-item label="债偿压力指数" label-class-name="my-label">{{ baiRong.debtRepayStress }}</el-descriptions-item>
      <el-descriptions-item label="反欺诈规则评分" label-class-name="my-label">{{ baiRong.afRuleScore }}</el-descriptions-item>
      <el-descriptions-item label="近7天申请线上消费分期的次数" label-class-name="my-label">{{ baiRong.d7CoonAllNum }}</el-descriptions-item>
      <el-descriptions-item label="近7天申请线上消费分期的机构数" label-class-name="my-label">{{ baiRong.d7CoonOrgNum }}</el-descriptions-item>
      <el-descriptions-item label="近7天在非银机构-持牌小贷机构申请次数" label-class-name="my-label">{{
        baiRong.d7NbankSloanAllNum
      }}</el-descriptions-item>
      <el-descriptions-item label="近7天在非银机构-持牌小贷机构申请机构数" label-class-name="my-label">{{
        baiRong.d7NbankSloanOrgNum
      }}</el-descriptions-item>
      <el-descriptions-item label="近15天在非银机构-持牌融资租赁机构申请次数" label-class-name="my-label">{{
        baiRong.d15NbankFinleaAllNum
      }}</el-descriptions-item>
      <el-descriptions-item label="近15天在非银机构周末申请机构数" label-class-name="my-label">{{ baiRong.d15NbankWeekOrgNum }}</el-descriptions-item>
      <el-descriptions-item label="按身份证号查询，近15天在非银机构申请次数" label-class-name="my-label">{{
        baiRong.d15IdNbankAllNum
      }}</el-descriptions-item>
      <el-descriptions-item label="近1个月申请汽车金融的机构数" label-class-name="my-label">{{ baiRong.m1AfOrgNum }}</el-descriptions-item>
      <el-descriptions-item label="按身份证号查询，近1个月在非银机构申请次数" label-class-name="my-label">{{
        baiRong.m1IdNbankAllNum
      }}</el-descriptions-item>
      <el-descriptions-item label="按身份证号查询，近3个月在非银机构申请次数" label-class-name="my-label">{{
        baiRong.m3IdNbankAllNum
      }}</el-descriptions-item>
      <el-descriptions-item label="近6个月在非银机构申请次数" label-class-name="my-label">{{ baiRong.m6NbankAllNum }}</el-descriptions-item>
      <el-descriptions-item label="近6个月在非银机构-其他申请次数" label-class-name="my-label">{{ baiRong.m6NbankOthAllNum }}</el-descriptions-item>
      <el-descriptions-item label="近6个月在非银机构-消费类分期机构申请次数" label-class-name="my-label">{{
        baiRong.m6NbankCfAllNum
      }}</el-descriptions-item>
      <el-descriptions-item label="按身份证号查询，近6个月在非银机构申请次数" label-class-name="my-label">{{
        baiRong.m6IdNbankAllNum
      }}</el-descriptions-item>
    </el-descriptions>
    <div class="title">优分</div>
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="4" border>
      <el-descriptions-item label="运营商在网时长" label-class-name="my-label">{{ youFen.netTime }}</el-descriptions-item>
      <el-descriptions-item label="信优分" label-class-name="my-label">{{ youFen.letterScore }}</el-descriptions-item>
      <el-descriptions-item label="综合风险验证" label-class-name="my-label">{{ youFen.riskCheck }}</el-descriptions-item>
      <el-descriptions-item label="自然人可信度识别" label-class-name="my-label">{{ youFen.identityCredence }}</el-descriptions-item>
      <el-descriptions-item label="疑似养卡" label-class-name="my-label">{{ youFen.suspendCard }}</el-descriptions-item>
      <el-descriptions-item label="申请机构总数" label-class-name="my-label">{{ youFen.applyOrgNum }}</el-descriptions-item>
      <el-descriptions-item label="放款最小金额距今最早天数" label-class-name="my-label">{{ youFen.minLoanMoneyHistoryDay }}</el-descriptions-item>
      <el-descriptions-item label="最早放款距今天数" label-class-name="my-label">{{ youFen.loanHistoryDay }}</el-descriptions-item>
      <el-descriptions-item label="最近逾期距今天数" label-class-name="my-label">{{ youFen.overdueRecentlyDay }}</el-descriptions-item>
      <el-descriptions-item label="最早逾期距今天数" label-class-name="my-label">{{ youFen.overdueHistoryDay }}</el-descriptions-item>
      <el-descriptions-item label="逾期最大金额距今最近天数" label-class-name="my-label">{{ youFen.overdueMaxMoneyRecentlyDay }}</el-descriptions-item>
      <el-descriptions-item label="逾期最大金额距今最早天数" label-class-name="my-label">{{ youFen.overdueMaxMoneyHistoryDay }}</el-descriptions-item>
      <el-descriptions-item label="近12月逾期次数" label-class-name="my-label">{{ youFen.m12OverdueNum }}</el-descriptions-item>
      <el-descriptions-item label="近24月逾期次数" label-class-name="my-label">{{ youFen.m24OverdueNum }}</el-descriptions-item>
      <el-descriptions-item label="个人涉诉数量" label-class-name="my-label">{{ youFen.totalInvolvementNum }}</el-descriptions-item>
      <el-descriptions-item label="金融黑名单等级" label-class-name="my-label">{{ youFen.blacklistLevel }}</el-descriptions-item>
  
    </el-descriptions>
    <div style="height:50px;"></div>
  </div>
</template>

<script>
import { getOrderRiskAPI } from './api'
export default {
  name: 'DetailRisk',

  data() {
    return {
      riskInfo: {},
      baiRong: {},
      youFen: {}
    }
  },

  mounted() {
    this.getOrderRisk()
  },

  methods: {
    async getOrderRisk() {
      const res = await getOrderRiskAPI(this.$route.query.memberId)
      this.riskInfo = res
      this.baiRong = res.baiRong || {}
      this.youFen = res.youFen || {}
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .title {
    font-weight: 600;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }
  .sub-title {
    margin: 10px 0;
  }
}
</style>
